import React from 'react'

import Navbar from './components/Navbar'
import Home from './components/Home'
import About from './components/About'
import Skills from './components/Skills'
import SideQuests from './components/SideQuests'
import Contact from './components/Contact'


import Particles from 'react-particles-js'
// import ParallaxBg from './components/ParallaxBg'

const particlesOptions = {
  // background: {
  //   color: {
  //     value: "#140f25"
  //   }
  // },

  particles: {

    number: {
      value: 80,
      density: {
        enable: true,
        value_area: 800 }
    },

    color: { value: '#140f25' },

    shape: {
      type: 'circle',
      stroke: { 
        width: 2,
        color: '#140f25' 
      }
    },

    polygon: {
      nb_sides: 5
    },

    image: {
      src: 'img/github.svg',
      width: 100,
      height: 100
    },

    opacity: {
      value: 0.5,
      random: false
    },
    anim: {
      'enable': true,
      'speed': 1,
      'opacity_min': 0.1,
      'sync': false
    } },

  size: {
    value: 3,
    random: true,
    anim: {
      enable: false,
      speed: 40,
      size_min: 0.1,
      sync: false
    } },

  line_linked: {
    enable: true,
    distance: 150,
    color: '#ffffff',
    opacity: 0.4,
    width: 1
  },
    
  move: {
    enable: true,
    speed: 2,
    direction: 'none',
    random: false,
    straight: false,
    out_mode: 'out',
    bounce: true,
    attract: {
      enable: true,
      rotateX: 600, 
      rotateY: 1200
    } },
    
  interactivity: {
    detectsOn: 'canvas',
    events: {
      onhover: { 
        enable: true, 
        mode: 'grab'
      },

      onclick: { 
        enable: true, 
        mode: 'push' 
      },

      resize: true
    },

    modes: {
      grab: {
        distance: 400,
        line_linked: {
          opacity: 1
        } },

      bubble: {
        distance: 400,
        size: 40,
        duration: 2,
        opacity: 8,
        speed: 3
      },

      repulse: {
        distance: 200,
        duration: 0.4
      },

      push: {
        quantity: 4
      },

      remove: {
        particles_nb: 2
      } },
    detectRetina: true 
  }
}

const App = () => {
  const [scrollbar, setScrollbar] = React.useState('0%')

  React.useEffect(() => {
    const listenToScrollEvent = () => {
      document.addEventListener('scroll', () => {
        const totalHeight = document.body.scrollHeight - window.innerHeight
        const progressHeight = ((window.pageYOffset / totalHeight) * 100) + '%'
        setScrollbar(progressHeight)
      })
    }
    listenToScrollEvent()
  }, )

  // particlesJS("particles-js", {"particles":{"number":{"value":80,"density":{"enable":true,"value_area":800}},"color":{"value":"#ffffff"},"shape":{"type":"circle","stroke":{"width":0,"color":"#000000"},"polygon":{"nb_sides":5},"image":{"src":"img/github.svg","width":100,"height":100}},"opacity":{"value":0.5,"random":false,"anim":{"enable":false,"speed":1,"opacity_min":0.1,"sync":false}},"size":{"value":3,"random":true,"anim":{"enable":false,"speed":40,"size_min":0.1,"sync":false}},"line_linked":{"enable":true,"distance":150,"color":"#ffffff","opacity":0.4,"width":1},"move":{"enable":true,"speed":6,"direction":"none","random":false,"straight":false,"out_mode":"out","bounce":false,"attract":{"enable":false,"rotateX":600,"rotateY":1200}}},"interactivity":{"detect_on":"canvas","events":{"onhover":{"enable":true,"mode":"grab"},"onclick":{"enable":true,"mode":"push"},"resize":true},"modes":{"grab":{"distance":400,"line_linked":{"opacity":1}},"bubble":{"distance":400,"size":40,"duration":2,"opacity":8,"speed":3},"repulse":{"distance":200,"duration":0.4},"push":{"particles_nb":4},"remove":{"particles_nb":2}}},"retina_detect":true});var count_particles, stats, update; stats = new Stats; stats.setMode(0); stats.domElement.style.position = 'absolute'; stats.domElement.style.left = '0px'; stats.domElement.style.top = '0px'; document.body.appendChild(stats.domElement); count_particles = document.querySelector('.js-count-particles'); update = function() { stats.begin(); stats.end(); if (window.pJSDom[0].pJS.particles && window.pJSDom[0].pJS.particles.array) { count_particles.innerText = window.pJSDom[0].pJS.particles.array.length; } requestAnimationFrame(update); }; requestAnimationFrame(update);;

  return (
    <>
      <Navbar/>
      <main>

        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute' }}>
            <Particles className="particles" params={particlesOptions} />
          </div> 
        </div> 

        <div id="progressbar" style={ { height: scrollbar } }></div>
        <div id="scrollPath"></div>
        {/* <ParallaxBg/> */}
        <Home/>
        <About/>
        <Skills/>
        <SideQuests/>
        <Contact/>
      </main>
    </>
  )
}

export default App
